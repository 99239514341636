@media (min-width: 665px) {
    .container {
        position: relative;
        overflow: hidden;
        height: auto;
        background: #C9EBFF;
        transition: 0.3s;
    }
    .container .slider {
        height: 300px;
        white-space: nowrap;
        font-size: 0;
        transition: 0.8s;
        padding-top: 10px;
    }
    .iframe-container {
        padding-top: "0%";
        margin-bottom: "0rem";
        align-items: "center";
        justify-content: "center";
        vertical-align: "middle";
        margin: auto;
        width: 50%;
        height: 100%;
    }
    
    .iframe {
        border: "none";
        overflow: "hidden";
        vertical-align: "middle";
        display: "block";
        margin: "auto";
    }
    .prevNext a {
        background: #555657;
        position: absolute;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        opacity: 0.7;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        cursor: pointer;
        margin-left: 10%;
        margin-right: 10%;
        border-radius: 50%;
        color: white;
    }
}

@media (max-width: 664px) {
    .container {
        position: relative;
        overflow: hidden;
        height: auto;
        background: #C9EBFF;
        transition: 0.3s;
    }
    .container .slider {
        height: 340px;
        white-space: nowrap;
        font-size: 0;
        transition: 0.8s;
        padding-bottom: 2rem;
    }
    .iframe-container {
        padding-top: "0%";
        /* max-height: "340px"; */
        align-items: "center";
        justify-content: "center";
        padding-bottom: "10%";
        margin: auto;
        width: 60%;
        height: 100%;
    }
    
    .iframe {
        margin-bottom: "1rem";
        border: "none";
        overflow: "hidden";
        vertical-align: "middle";
        display: "block";
        margin: "auto";
    }
    .prevNext a {
        background: #555657;
        position: absolute;
        width: 45px;
        height: 45px;
        line-height: 45px; 
        text-align: center;
        opacity: 0.7;
        transition: 0.3s;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        cursor: pointer;
        margin-left: 5%;
        margin-right: 5%;
        border-radius: 50%;
        color: white;
    }
}

@media (max-width: 430px) {
    .container {
        position: relative;
        overflow: hidden;
        height: auto;
        background: #C9EBFF;
        transition: 0.3s;
    }  
    .iframe-container {
        padding-top: "0%";
        max-height: "340px";
        align-items: "center";
        justify-content: "center";
        padding-bottom: "10%";
        margin: auto;
        width: 73%;
        height: 100%;
    }   
    .prevNext a {
        background: #555657;
        position: absolute;
        width: 35px;
        height: 35px;
        line-height: 35px; 
        text-align: center;
        opacity: 0.7;
        transition: 0.3s;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        cursor: pointer;
        margin-left: 2%;
        margin-right: 2%;
        border-radius: 50%;
        color: white;
    }
    
    .prevNext a::before {
        line-height: 35px;
    }
}

body {
    margin: 0;
    font: 16px/1.3 sans-serif;
}

.container .slider > * {
    font-size: 1rem;
    white-space: normal;
    vertical-align: top;
    height: 100%;
    width: 100%;
}

.review.inactive.next {
    position: absolute;
    display: block;

    /* POP ANIMATION */
    /* opacity: 0;
    transition-duration: 1s ease; */

    /* SLIDE ANIMATION */
    animation: exit-left 0.45s ease-out forwards;
}

.review.inactive.prev {
    position: absolute;
    display: block;

    /* POP ANIMATION */
    /* opacity: 0;
    transition-duration: 1s ease; */

    /* SLIDE ANIMATION */
    animation: exit-right 0.45s ease-out forwards;
}

.review.active.next {
    display: block;
    position: absolute;

    /* POP ANIMATION */
    /* opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08); */

    /* SLIDE ANIMATION */
    animation: enter-from-right 0.45s ease-out forwards;
}

.review.active.prev {
    display: block;
    position: absolute;

    /* POP ANIMATION */
    /* opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08); */

    /* SLIDE ANIMATION */
    animation: enter-from-left 0.45s ease-out forwards;
}


/* NAVIGATION BUTTONS */

.prevNext {
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 100%;
    height: 0;
}

.prevNext a:hover {
    opacity: 1;
}


.prevNext a + a {
    left: auto;
    right: 0;
}

/* ANIMATIONs */
@keyframes enter-from-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes exit-left {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes enter-from-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes exit-right {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}